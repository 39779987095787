import styled from "@emotion/styled";
import type { FC, RefObject } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IconCheckmarkCompleted } from "src/constants/icons";
import createTrackEvent from "src/lib/createTrackEvent";
import HistoryBloc from "src/state/HistoryBloc/HistoryBloc";
import type { QuestionnaireState } from "src/state/QuestionnaireCubit/QuestionnaireState";
import { tracker, useBloc } from "src/state/state";
import Translate from "src/ui/components/Translate/Translate";
import CustomSuspense from "../CustomSuspense/CustomSuspense";
import InDialog from "../InDialog/InDialog";

const AsyncCustomQuestionnaire = React.lazy(
  async () => import("../CustomQuestionnaire/CustomQuestionnaire")
);

const CenterQuestionnaireContent = styled.div`
  label: CenterQuestionnaireContent;
  display: grid;
  place-items: center;
  min-height: 100%;
  width: 100%;
  padding: 2rem 1rem 5rem !important;
  margin-bottom: calc(var(--keyboard-height, 50vh), 5rem);

  > div {
    width: 100%;
  }
`;

const CenterEl = styled.div`
  border-radius: 0.6em;
  width: 90vw;
  max-width: 580px;
  text-align: center;
  margin: 0 auto;

  p {
  }

  button {
    margin-top: 1em;
    font-size: 1em;
    padding: 0.6em 1.2em;
  }

  a {
    color: var(--color-sunrise-blue);
  }

  svg {
    width: 3rem;
    height: auto;
    border: 2px solid var(--color-charcoal-60);
    border-radius: 50%;
  }
`;

const InAppQuestionnaire: FC<{
  returnUrl?: string;
}> = ({ returnUrl }) => {
  const [historyState] = useBloc(HistoryBloc);
  const [questionnaireData, setQuestionnaireData] = useState<
    QuestionnaireState | undefined
  >(undefined);
  const { id = "" } = useParams<{ id?: string }>();
  const [query] = useSearchParams();
  const questionIdQuery = query.get("questionnaireId") ?? "";
  const title = query.get("title");
  const fullscreen = query.get("fullscreen");
  const dialogType = query.get("dialog-type") ?? "";
  const [completed, setCompleted] = useState(false);
  const [overlayRef, setOverlayRef] =
    useState<RefObject<HTMLIonModalElement>>();
  const navigate = useNavigate();

  const preOpenAppPage = useMemo<string>(() => {
    if (returnUrl) return returnUrl;
    // find last url that starts with "/app", but does not include "/app/questionnaire"
    const justPathnames = historyState.history
      .map(({ pathname }) => pathname)
      .reverse();
    const appPage = justPathnames.find(
      (url) => url.startsWith("/app") && !url.startsWith("/app/questionnaire")
    );

    return appPage ?? "/app";
  }, [historyState]);

  const handleClose = () => {
    if (overlayRef?.current) {
      void overlayRef.current.dismiss();
    }
    navigate(preOpenAppPage);
  };

  useEffect(() => {
    if (!questionnaireData?.formMeta.title) return;

    tracker.track(createTrackEvent("In-App Questionnaire Opened"), {
      data: {
        "Questionnaire ID": questionnaireData.formId,
        "Questionnaire Title": questionnaireData.formMeta.title
      }
    });
  }, [questionnaireData?.formId]);

  const useQuestionnaireId = questionIdQuery || id;

  return (
    <InDialog
      onClose={handleClose}
      title={title ?? "Questionnaire"}
      setRef={setOverlayRef}
      returnUrl={returnUrl ?? preOpenAppPage}
      backdropDismiss={false}
      height={fullscreen ? "100vh" : "800px"}
      width={fullscreen ? "100vw" : "700px"}
      dialogType={dialogType}
    >
      <CenterQuestionnaireContent>
        {completed && (
          <div>
            <CenterEl>
              <nine-heading>
                <IconCheckmarkCompleted />
                <>
                  <nine-spacer s="md"></nine-spacer>
                  <h4>
                    <Translate msg="questionnaire.completed" />
                  </h4>
                  <nine-spacer s="md"></nine-spacer>
                  <p className="m0 color-c-80">
                    <Translate msg="questionnaire.thankYouMessage" />
                  </p>
                </>
              </nine-heading>
              <nine-spacer s="xl"></nine-spacer>
              <nine-button onClick={handleClose} variant="fill" color="black">
                <Translate msg="button.backToApp" />
              </nine-button>
            </CenterEl>
          </div>
        )}
        {!completed && (
          <CustomSuspense>
            <AsyncCustomQuestionnaire
              autoSkip={true}
              resetScrollOnEachStep
              onQuestionnaireData={setQuestionnaireData}
              id={useQuestionnaireId}
              useRouting
              onSubmit={async (): Promise<boolean> => {
                setCompleted(true);
                return false;
              }}
            />
          </CustomSuspense>
        )}
      </CenterQuestionnaireContent>
    </InDialog>
  );
};

export default InAppQuestionnaire;
